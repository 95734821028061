@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suD8S72Q.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuvMQg.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuvMQg.ttf) format('truetype');
}

:root {
  --main-color: rgb(9, 37, 62);
  --main-transparent: rgba(9, 37, 62, 0.8);
  --white-transparent: rgba(255,255,255,0.7);
  --white-less-transparent: rgba(255,255,255,0.4);
  --sec-color: rgb(122, 48, 63);
}

.App {
    margin: 0;
    font-family: Open Sans;
    font-size: 14px;
    line-height: 1.44em;
    font-weight: 400;
    color: var(--main-color);
    overflow-x: hidden;
    -webkit-font-smoothing:antialiased;
    background-color: transparent;
}

.App .selectType{
    display: flex;
}

.App .selectType a{
    margin: 0 8px;
}

.App p {
    font-family: Open Sans;
    font-size: 2vh;
    line-height: 22px;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    margin-top: 8px;
    margin-bottom: 8px;
}

.App h1, .App h2, .App h3, .App h4, .App h5, .App h6 {
    margin:0;
    text-align: center;
}

.App h1 {
  font-family: Open Sans Condensed;
  font-size: 60px;
  line-height: 70px;
}
.App h2 {
    font-family: Open Sans Condensed;
    font-size: 40px;
    line-height: 50px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.App h3 {
  font-family: Open Sans Condensed;
  font-size: 34px;
  line-height: 45px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.App h4 {
    font-family: Open Sans Condensed;
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
}

.App h5 {
    font-family: Open Sans Condensed;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

.App h6 {
    font-family: Open Sans Condensed;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.App a, p a {
    font-style: normal;
    font-weight: 400;
    color: var(--main-color);
    text-decoration: none;
}

.App a:hover, p a:hover {
    color: var(--sec-color);
    text-decoration: underline;
}

/* TABLE */
.App table{
    border-collapse: collapse;
}
.App thead{
    font-family: Open Sans Condensed;
    font-weight: 800;
}
.App .boldrow{
    font-family: Open Sans Condensed;
    font-weight: 800;
}
.App td, .App th{
border: 1px solid #ddd;
  padding: 4px;
}

/* MEDIA */
@media only screen and (max-width: 320px) {
  .App h1 {
      font-size: 30px !important;
      line-height:40px !important
  }

  .App h2 {
      font-size: 22px !important;
      line-height:30px !important
  }

  .App h3 {
      font-size: 20px !important;
      line-height:30px !important
  }
}
@media only screen and (max-width: 680px) {
    .App h1 {
        font-size: 35px !important;
        line-height:45px !important
    }

    .App h2 {
        font-size: 27px !important;
        line-height:35px !important
    }

    .App h3 {
        font-size: 25px !important;
        line-height:35px !important
    }
}
@media only screen and (max-width: 1024px) {
    .App h1 {
        font-size: 45px !important;
        line-height:55px !important
    }

    .App h2 {
        font-size: 35px !important;
        line-height:45px !important
    }

    .App h3 {
        font-size: 30px !important;
        line-height:40px !important
    }
}
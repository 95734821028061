@font-face {
    font-family: 'kenyan';
    src: url('../kenyan.ttf') format('truetype');
  }
  @font-face {
    font-family: 'acumin-pro-extra-condensed-black';
    src: url('../acumin-pro-extra-condensed-black.otf') format('opentype');
  }

.score img{
    width: 938px;
    height: 272px;
    position: absolute;
    top: 0;
    left: 0;
}
.scoreSpecial img{
    width: 1230px;
    height: 377px;
    position: absolute;
    top: 0;
    left: 0;
}
.score{
    color: white;
    font-size: 80px;
    font-family: kenyan,inherit auto;
}
.scoreSpecial{
    color: white;
    font-size: 160px;
    font-family: acumin-pro-extra-condensed-black,inherit auto;
}
.blue{
    color: rgb(9, 37, 62);
}
.scoreSpecial .blue{
    color: #005dac;
}
.score div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.scoreSpecial div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.nameOne{
    position: absolute;
    height: 127px;
    width: 399px;
    top: 21px;
    left: 282px;
}
.scoreSpecial .nameOne{
    position: absolute;
    height: 200px;
    width: 494px;
    top: 30px;
    left: 380px;
}
.nameTwo{
    position: absolute;
    height: 127px;
    width: 399px;
    top: 155px;
    left: 282px;
}
.scoreSpecial .nameTwo{
    position: absolute;
    height: 200px;
    width: 494px;
    top: 208px;
    left: 380px;
}
.setOne{
    position: absolute;
    height: 127px;
    width: 127px;
    top: 21px;
    left: 688px;
}
.scoreSpecial .setOne{
    position: absolute;
    width: 127px;
    left: 902px;
    height: 200px;
    top: 30px;
}
.setTwo{
    position: absolute;
    height: 127px;
    width: 127px;
    top: 155px;
    left: 688px;
}
.scoreSpecial .setTwo{
    position: absolute;
    width: 127px;
    left: 902px;
    height: 200px;
    top: 208px;
}
.scoreOne{
    position: absolute;
    height: 127px;
    width: 127px;
    top: 21px;
    left: 821px;
}
.scoreSpecial .scoreOne{
    position: absolute;
    width: 127px;
    left: 1082px;
    height: 200px;
    top: 30px;
}
.scoreTwo{
    position: absolute;
    height: 127px;
    width: 127px;
    top: 155px;
    left: 821px;
}
.scoreSpecial .scoreTwo{
    position: absolute;
    width: 127px;
    left: 1082px;
    height: 200px;
    top: 208px;
}
body{
    margin: 0;
}
.App{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 1000px; */
}
.App img{
    margin: 16px;
    width: 200px;
}
.App a{
    cursor: pointer;
}
.App table{
    text-align: center;
    margin: 16px 0;
    height: 1px;
}
.App td{
    border: none;
    padding: 8px;
    background: rgb(48,46,47);
    background: linear-gradient(0deg, rgba(48,46,47,1) 0%, rgba(62,62,60,1) 100%);
    color: white;
    font-weight: bold;
}
.App .img{
    padding: 0;
    overflow: hidden;
}
.App .img img{
    height: 70px;
    width: 70px;
    margin: 0;
    margin-bottom: -1px;
}

.App td h6, .App .App td h5{
    text-align: left;
    white-space: nowrap;
}
.App .nametd{
    text-shadow: 0px 7px 10px rgba(81,67,21,0.8);
}
.App .none{
    background: none;
}
.App .cup{
    background: #FEBD12;
    color: #222222;
}
.App .white{
    background: #F0F0EE;
    color: #222222;
}
.App thead td{
    font-size: 14px;
    background: #222222;
}
.App tbody td{
    font-size: 24px;
}
.small{
    display: none;
}

@media only screen and (max-width: 600px) {
    .emoji, .img{
        display: none;
    }
  }

@media only screen and (max-width: 500px) {
    
    .App tbody td{
        font-size: 20px;
    }
    .App h5{
        font-size: 18px;
    }
    .large{
        display: none;
    }
    .small{
        display: inline;
    }
}
@media only screen and (max-width: 400px) {
    
    .App tbody td{
        font-size: 18px;
    }
    .App h5{
        font-size: 16px;
    }
}
@media only screen and (max-width: 340px) {
    
    .App tbody td{
        font-size: 18px;
    }
    .App h5{
        font-size: 16px;
    }
    .tiny{
        display: none;
    }
    .App td{
        padding: 4px;
    }
}